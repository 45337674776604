import React, { useState } from 'react';
import axios from 'axios';

function App() {
  const [email, setEmail] = useState('user1@unismuh.ac.id');
  const [password, setPassword] = useState('password1');
  const [message, setMessage] = useState('');
  const [accessToken, setAccessToken] = useState('');

  const handleLogin = () => {

    const FormData = require('form-data');
    let data = new FormData();
    data.append('password', 'password1');
    data.append('email', 'user1@unismuh.ac.id');
    
    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: 'https://apps.unismuh.ac.id/auth2/login',
      data : data,
      withCredentials: true
    };
    
    axios.request(config)
    .then((response) => {
      console.log(JSON.stringify(response.data));
      // setAccessToken(response.data.access_token);
      // setMessage('Login successful');

    })
    .catch((error) => {
      console.log(error);
    });
    
    // axios.post('https://apps.unismuh.ac.id/auth2/login', { email, password }, { withCredentials: false })
    //   .then(response => {
    //     setAccessToken(response.data.access_token);
    //     setMessage('Login successful');
    //   })
    //   .catch(error => {
    //     setMessage(error.response.data.message);
    //   });
  };

  const handleCheckAuth = () => {
    axios.get('https://apps.unismuh.ac.id/auth2/check-auth', {
      headers: { Authorization: `Bearer ${accessToken}` }
    })
      .then(response => {
        setMessage(response.data.message);
      })
      .catch(error => {
        setMessage(error.response.data.message);
      });
  };

  const handleRefresh = () => {
    axios.post('https://apps.unismuh.ac.id/auth2/refresh', {}, { withCredentials: true })
      .then(response => {
        setAccessToken(response.data.access_token);
        setMessage('Access token refreshed');
      })
      .catch(error => {
        setMessage(error.response.data.message);
      });
  };

  const handleLogout = () => {
    axios.post('https://apps.unismuh.ac.id/auth2/logout', {}, { withCredentials: true })
      .then(response => {
        setMessage(response.data.message);
        setAccessToken('');
      })
      .catch(error => {
        setMessage(error.response.data.message);
      });
  };

  return (
    <div style={{ padding: '20px' }}>
      <h1>SSO with Access Token and Refresh Token</h1>
      <div>
        <input
          type="text"
          placeholder="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <button onClick={handleLogin}>Login</button>
      </div>
      <div>
        <button onClick={handleCheckAuth}>Access Protected Route</button>
        <button onClick={handleRefresh}>Refresh Token</button>
        <button onClick={handleLogout}>Logout</button>
      </div>
      <p>{message}</p>
    </div>
  );
}

export default App;
